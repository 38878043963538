import {Formik, Form, Field, ErrorMessage} from 'formik';
import React, {useContext, useEffect, useState} from "react";
import {Modal} from 'reactstrap';
import CurrencyInput from 'react-currency-masked-input'
import './MakePayment.scss';
import StatusMessage from "../../components/statusMessage/StatusMessage";
import messages from "../../config/constant/messages";
import CalendarIcon from "../../assets/images/svgs/calendar-icon.svg";
import Calendar from 'react-calendar';
import Button from '../../components/button/Button';
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import query from "../../query";
import {Link} from "react-router-dom";
import PaymentDetailContext from "../../context/paymentDetailContext";
import Loader from "../../components/loader/Loader";
import moment from "moment";
import ContentBox from "../../components/contentBox/ContentBox";
import ReadOnlyInput from "../../components/readOnlyInput/ReadOnlyInput";
import LoanDetailContext from "../../context/loanDetailContext";
import PaymentInfoBox from "./PaymentInfoBox";
import {PaymentConformation} from "./PaymentConformation";

const MakePayment = () => {

  const {paymentDetail, updatePaymentData, message} = useContext(PaymentDetailContext);
  const {loan_id: loanId} = useContext(LoanDetailContext);

  const [loan, setLoan] = useState();
  const [paymentMethods, setPaymentMethods] = useState();
  const [activePaymentAmountBox, setActivePaymentAmountBox] = useState();
  const [activePaymentDateBox, setActivePaymentDateBox] = useState();
  const [formStep, setFormStep] = useState(0);
  const [isOpenDatePickerModal, openDatePickerModal] = useState(false);
  const [showAutopayWarning, setShowAutopayWarning] = useState(false);
  const [nextScheduledPayment, setNextScheduledPayment] = useState();
  const [cancelNextScheduledPaymentStatus, setCancelNextScheduledPaymentStatus] = useState(false);
  const [paymentError, setPaymentError] = useState();
  const [paymentData, setPaymentData] = useState();
  const [showCardFee, setShowCardFee] = useState(false);
  const [creditCardFee, setCreditCardFee] = useState();
  const [cardPromoEnabled, setCardPromoEnabled] = useState();
  const [creditCardPromoCoveredUntilAt, setCreditCardPromoCoveredUntilAt] = useState();
  const [isCreditCardPromoCovered, setCreditCardPromoCovered] = useState();
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState();
  const [currentPaymentMethodId, setCurrentPaymentMethodId] = useState();
  const {loading, data: paymentDetailsData, refetch} = useQuery(query.getPaymentDetails, {
    variables: {
      loanId: loanId,
    },
  });

  const [getNextScheduledPayment, {data: loanNextScheduledPaymentsData}] = useLazyQuery(query.getNextScheduledPayment, {
    variables: {
      loanId: loanId,
    }
  })

  const [submitPayment, {
    data: lastPaymentData,
    loading: submitPaymentLoading,
    error: submitPaymentError
  }] = useMutation(query.submitPayment);

  const start = () => {
    setFormStep(0);
    refetch();
    getNextScheduledPayment();
    if (paymentDetailsData) {
      setLoan(paymentDetailsData['loans'][0]);
      setCreditCardFee(paymentDetailsData['loans'][0]['creditCardFee']);

      if (paymentDetailsData['paymentMethods'] && paymentDetailsData['paymentMethods'].length > 0) {
        setCardPromoEnabled(paymentDetailsData['paymentMethods'][0]['cardPromoEnabled']);
        setCreditCardPromoCoveredUntilAt(paymentDetailsData['paymentMethods'][0]['creditCardPromoCoveredUntilAt']);
        setPaymentMethods(paymentDetailsData['paymentMethods']);
      }
    }

    if (loanNextScheduledPaymentsData) {
      setNextScheduledPayment(loanNextScheduledPaymentsData.nextScheduledPayment)
    }
  }

  useEffect(() => {
    start()
  }, [paymentDetailsData, loanNextScheduledPaymentsData, paymentDetail])

  useEffect(() => {
    if (loan && isDateBeforeToday(loan['nextPaymentDate'])) {
      setShowAutopayWarning(true);
    }
  }, [loan])

  useEffect(() => {
    if (lastPaymentData) {
      setPaymentData(lastPaymentData['postPayment'])
      setPaymentError(undefined);
      setFormStep(1);
      const selectedPaymentMethod = paymentMethods && paymentMethods.find(paymentMethod => paymentMethod['id'] == lastPaymentData['postPayment']['paymentMethodId'])
      setCurrentPaymentMethod(selectedPaymentMethod);
    }
    if (submitPaymentError) {
      if(currentPaymentMethod['transactionType'] === 'creditcard'){
        setPaymentError({message: messages.paymentCreditCardError})
      }else{
        setPaymentError(submitPaymentError)
      }
    }
  }, [lastPaymentData, submitPaymentError]);

  const creditCardCollection = () => paymentMethods && paymentMethods.filter(methods => methods.transactionType === 'creditcard').map(({id}) => id);

  const validatePaymentCardThreshold = (paymentMethod, amount) => {
    return amount > paymentMethod['cardPaymentThreshold'];
  }

  const getPaymentCardProcessingFee = (paymentMethod, amount) => {
    return Number(((amount/100) * paymentMethod['cardPaymentProcessingPercentage']).toFixed(2))
  }

  const calculateConvenienceFee = (paymentMethod, amount, paymentDateText) => {
    if(paymentMethod && amount){
      const creditCardIds = creditCardCollection();
      let amountt = parseFloat(amount.replace(',', ''));
      if(!creditCardIds.includes(paymentMethod.id)){
        setCreditCardFee(0);
        setShowCardFee(false);
      }else{
        const [month, day, year] = paymentDateText.split('/').map(Number);
        const paymentDate = new Date(Date.UTC(year, month - 1, day));
        const coveredFee = cardPromoEnabled && creditCardPromoCoveredUntilAt !== null && new Date(creditCardPromoCoveredUntilAt) >= paymentDate;

        setCreditCardPromoCovered(coveredFee);

        if(validatePaymentCardThreshold(paymentMethod, amountt)){
          setCreditCardFee(getPaymentCardProcessingFee(paymentMethod, amountt));
        }else{
          setCreditCardFee(paymentMethod['cardPaymentDefaultFee']);
        }
        setShowCardFee(true);
      }
    }
  } 

  const validate = (value) => {
    let error;
    if (!value) {
      error = messages.required;
    }
    return error;
  }

  const isDateBefore = (date1, date2) => new Date(date1) > new Date(date2);
  const isDateBeforeToday = (date) => isDateBefore(date, new Date().toDateString());

  return <div className={'payment-form-container'}>
    <h3 className={'font-weight-bold'}>Provide payment information</h3>
    {nextScheduledPayment && (<Link to="/AccountSummary">
      <StatusMessage
        color={'danger'}
        value={messages.existedPaymentWarning}
        allowClose={true}
        className="d-flex"/>
    </Link>)}
    {showAutopayWarning && <StatusMessage
      color={'danger'}
      value={`You\'re currently setup on Autopay to be debited on ${loan['nextPaymentDate']}`}
      allowClose={true}
      className="d-flex"
    />}
    {paymentError && <StatusMessage
      color={'danger'}
      value={paymentError['message']}
      allowClose={true}
      className="d-flex"
    />}
    {message && <StatusMessage value={message} allowClose={true} className="d-flex"/>}
    {
      formStep == 1 ? <PaymentConformation paymentData={paymentData}
                                           currentPaymentMethod={currentPaymentMethod}
                                           cancelNextScheduledPaymentStatus={cancelNextScheduledPaymentStatus}
                                           setFormStep={() => setFormStep(0)}
                                           convenienceFeeCovered={(cardPromoEnabled && isCreditCardPromoCovered && currentPaymentMethod['transactionType'] === 'creditcard')}
        /> :
        <div className={'d-flex'}>
          {loading && 'Loading...'}
          {loan && paymentMethods &&
          <Formik
            initialValues={{
              loanId: parseInt(loanId),
              date: paymentDetail['date'] || '',
              amount: paymentDetail['amount'] || '',
              paymentMethodId: paymentDetail['paymentMethodId'] || '',
              cancelNextScheduledPayment: false,
              extraPayment: "false"
            }}
            onSubmit={(values, {setSubmitting}) => {
              let amount = parseFloat(values.amount.replace(/,/g, ''));
              let formattedValues = {
                ...values,
                paymentMethodId: parseInt(values.paymentMethodId),
                amount: amount,
                cancelNextScheduledPayment: cancelNextScheduledPaymentStatus,
                extraPayment: values.extraPayment === 'true'
              }
              submitPayment({variables: formattedValues})
                .then(res => setPaymentData(res.postPayment)
                )
                .catch(err => setPaymentError(err))
            }}>
            {({values, setValues, errors, touched, isValid, isSubmitting, resetForm, dirty, setFieldValue}) => {

              const nextScheduledPaymentIsCancelled = () => {
                if (!nextScheduledPayment) return false;

                let amount = parseFloat(values.amount);
                let nextScheduledPaymentAmount = parseFloat(nextScheduledPayment['amount']);

                const today = moment();
                const paymentDate = moment(values.date);

                const isToday = today.isSame(paymentDate, 'day');
                const isAtLeastHalf = amount / nextScheduledPaymentAmount * 100 >= 50

                return isToday && isAtLeastHalf;
              }

              const paymentDate = values['date'];

              const handleAmountBoxClick = (boxName) => {
                let amount = loan[boxName].replace(/^\D+/g, '');
                let cancelNextScheduledPayment = nextScheduledPaymentIsCancelled();
                setCancelNextScheduledPaymentStatus(cancelNextScheduledPayment);
                setValues(prev => ({...prev, amount: amount, cancelNextScheduledPayment: cancelNextScheduledPayment}));
                calculateConvenienceFee(currentPaymentMethod, amount, paymentDate);
                setActivePaymentAmountBox(boxName);
              }

              const handleCustomAmountInput = () => {
                let cancelNextScheduledPayment = nextScheduledPaymentIsCancelled();
                setCancelNextScheduledPaymentStatus(cancelNextScheduledPayment);
                  
                setValues(prev => ({...prev, cancelNextScheduledPayment: cancelNextScheduledPayment}))
                setActivePaymentAmountBox('customAmount');
              };

              const handleAmountInput = (form, value) =>{
                form.setValues(prev => ({...prev, amount: value}));
                calculateConvenienceFee(currentPaymentMethod, value, paymentDate);
              }

              const handleDateBoxClick = (boxName) => {
                let cancelNextScheduledPayment = nextScheduledPaymentIsCancelled();
                setCancelNextScheduledPaymentStatus(cancelNextScheduledPayment);
                let date = loan[boxName] || moment(new Date()).format('MM/DD/YYYY');
                setValues(prev => ({...prev, date: date, cancelNextScheduledPayment: cancelNextScheduledPayment, extraPayment: "false"}));
                setActivePaymentDateBox(boxName);
              }

              const handlePaymentMethodChange = (event) => {
                let selectedPaymentMethodId = event.target.value
                setCurrentPaymentMethodId(selectedPaymentMethodId);
                const selectedPaymentMethod = paymentMethods && paymentMethods.find(paymentMethod => paymentMethod['id'] == selectedPaymentMethodId)
                setCurrentPaymentMethod(selectedPaymentMethod);
                setValues(prev => ({...prev, paymentMethodId: selectedPaymentMethodId}));
                calculateConvenienceFee(selectedPaymentMethod, values.amount, paymentDate);
              }

              const closeDatePicker = () => {
                setValues(prev => ({...prev, date: ''}));
                openDatePickerModal(false);
              }

              const resetFormState = () => {
                resetForm();
                setActivePaymentAmountBox(null);
                setActivePaymentDateBox(null);
              }

              return <Form>
                {submitPaymentLoading && <Loader/>}
                <div className={'my-4'}>
                  <div className={'d-flex align-items-center'}>
                    <div className="numberCircle m-2">1</div>
                    <h4 className={'mt-2'}>Select payment amount</h4>
                  </div>
                  <ErrorMessage name="amount" component="div" style={{color: 'red'}}/>
                  <div className="d-flex justify-content-center justify-content-md-start flex-wrap mb-3">
                    {loan && loan['daysPastDue'] > 0 &&
                    <ContentBox active={activePaymentAmountBox == 'pastDueAmount'} title={'Past Due Amount'}
                                footer={`${loan && loan['daysPastDue']} days past due`}
                                onClick={() => handleAmountBoxClick('pastDueAmount')}>
                      <ReadOnlyInput type="string" name="amount" value={loan && loan['pastDueAmount']}/>
                    </ContentBox>}
                    {loan && parseFloat(loan['currentDueBalance'].replace(/^\D+/g, '')) > 0 &&
                    <ContentBox active={activePaymentAmountBox == 'currentDueBalance'} title={'Total Amount Due'}
                                footer={`Due by ${loan && loan['nextPaymentDate']}`}
                                onClick={() => handleAmountBoxClick('currentDueBalance')}>
                      <ReadOnlyInput type="string" name="amount" value={loan && loan['currentDueBalance']}/>
                    </ContentBox>}
                    <ContentBox active={activePaymentAmountBox == 'balance'} title={'Payoff Amount'}
                                onClick={() => handleAmountBoxClick('balance')}>
                      <ReadOnlyInput type="string" name="amount" value={loan && loan['balance']}/>
                    </ContentBox>
                    <ContentBox active={activePaymentAmountBox == 'customAmount'} title={'Enter amount'}
                                onClick={handleCustomAmountInput}>
                      <Field type="string" name={"amount"} validate={validate}>
                        {({field, form}) => <CurrencyInput {...field} min="0" onChange={(e, value) => {
                          handleAmountInput(form, value);
                        }}/>}
                      </Field>
                    </ContentBox>
                  </div>
                </div>
                <hr/>
                <div className={'my-4'}>
                  <div className={'d-flex align-items-center'}>
                    <div className="numberCircle m-2">2</div>
                    <h4 className={'mt-2'}>Select payment date</h4>
                  </div>
                  <ErrorMessage name="date" component="div" style={{color: 'red'}}/>
                  <div className="d-flex justify-content-center justify-content-md-start flex-wrap mb-3">
                    <ContentBox active={activePaymentDateBox == 'earliestPaymentDate'} title={'Earliest Payment Date'}
                                onClick={() => handleDateBoxClick('earliestPaymentDate')}>
                      <ReadOnlyInput type="string" name="date" value={moment().format('MMM Do YYYY')}/>
                    </ContentBox>
                    <ContentBox active={activePaymentDateBox == 'nextPaymentDate'} title={'Due Date'}
                                onClick={() => handleDateBoxClick('nextPaymentDate')}>
                      <ReadOnlyInput type="string" name="date"
                                     value={loan && moment(new Date(loan['nextPaymentDate'])).format('MMM Do YYYY')}/>
                    </ContentBox>
                    <ContentBox active={activePaymentDateBox == 'customPaymentDate'} title={'Enter Date'}
                                onClick={() => handleDateBoxClick('customPaymentDate')}>
                      <div className={'d-flex'}>
                        <Field type="string" name="date" validate={validate} style={{width: '100%'}}/>
                        <div onClick={() => openDatePickerModal(true)}>
                          <div className={'m-1'}><img src={CalendarIcon} alt="calendar-icon"/></div>
                        </div>
                      </div>
                      {/* // Datepicker modal */}
                      <Modal isOpen={isOpenDatePickerModal} className="pick_your_date text-center d-flex">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title w-100 text-center" id="UpdateCustomerLabel">
                              Pick your Custom Date
                            </h5>
                          </div>
                          <div className="modal-body">
                            <div className="row justify-content-center">
                              <Calendar
                                value={new Date()}
                                onChange={(date) => {
                                  const formattedDate = moment(date).format('MM/DD/YYYY');
                                  setValues((prev) => ({
                                    ...prev,
                                    date: formattedDate
                                  }));

                                  calculateConvenienceFee(currentPaymentMethod, values.amount, formattedDate);
                                }}
                                minDate={new Date()}/>
                            </div>
                            <div className="modal-footer justify-content-center pb-0">
                              <Button
                                type="button"
                                data-dismiss="modal"
                                title="Cancel"
                                className="btn-custom-primary btn-custom-sm m-2 text-white"
                                onClick={closeDatePicker}
                              ></Button>
                              <Button
                                type="submit"
                                title="Submit"
                                hvrtitle="Submit Button"
                                className="btn btn-custom-primary alert-button text-white mt-0"
                                onClick={() => openDatePickerModal(prev => !prev)}
                              ></Button>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </ContentBox>
                  </div>
                </div>
                <hr/>
                <div className={'my-4'}>
                  <div className={'d-flex align-items-center'}>
                    <div className="numberCircle m-2">3</div>
                    <h4 className={'mt-2'}>Select payment method</h4>
                  </div>
                  <ErrorMessage name="paymentMethodId" component="div" style={{color: 'red'}}/>
                  <div className="d-flex justify-content-center justify-content-md-start flex-wrap">
                    <ContentBox title={'Payment methods'}>
                      <Field type="string"
                             name="paymentMethodId"
                             component='select'
                             validate={validate}
                             style={{width: '100%'}}
                             onChange={handlePaymentMethodChange}
                             value={currentPaymentMethodId || paymentDetail['paymentMethodId']}>
                        <option key={'default'} value={''}>Select method</option>
                        {paymentMethods && paymentMethods.map((paymentMethod, i) => <option key={i}
                                                                                            value={paymentMethod.id}>{paymentMethod.transactionType === 'creditcard' ? 'CARD' : 'ACH'} ****
                          **** {paymentMethod.lastFour}</option>)}
                      </Field>
                    </ContentBox>
                    <Link to='/PaymentMethods/AddingPaymentMethod'>
                      <ContentBox>
                        <button className={'btn btn-link'} onClick={() => updatePaymentData(values)}>Add Payment
                          Method
                        </button>
                      </ContentBox>
                    </Link>
                  </div>
                </div>
                {loan['daysPastDue'] === 0 && isDateBeforeToday(loan['nextPaymentDate']) && parseFloat(loan['currentDueBalance'].replace(/^\D+/g, '')) > 0 && !isDateBefore(values.date, loan['nextPaymentDate']) && <hr/>}
                {loan['daysPastDue'] === 0 && isDateBeforeToday(loan['nextPaymentDate']) && parseFloat(loan['currentDueBalance'].replace(/^\D+/g, '')) > 0 && !isDateBefore(values.date, loan['nextPaymentDate']) &&
                <div className={'my-4'}>
                  <div className={'d-flex align-items-center'}>
                    <div className="numberCircle m-2">4</div>
                    <h4 className={'mt-2'}>Select how apply the payment</h4>
                  </div>
                  <ErrorMessage name="extraPayment" component="div" style={{color: 'red'}}/>
                  <div className="d-flex justify-content-center justify-content-md-start flex-wrap">
                    <ContentBox title={'Options'} style={{width: '100%', height: '140px'}}>
                      <div role="group" aria-labelledby="extraPaymentGroup" style={{textAlign: 'left'}}>
                        <label>
                          <Field
                              name="extraPayment"
                              value="false"
                              type="radio"
                              checked={values.extraPayment === "false"}
                              onChange={() => setFieldValue("extraPayment", "false")}
                              style={{width: 'auto', marginRight: '3px'}}
                          />
                          Is this payment for {loan.nextPaymentDate} for the Amount of {loan.currentDueBalance}?
                        </label>
                        <br/>
                        <label>
                          <Field
                              name="extraPayment"
                              value="true"
                              type="radio"
                              checked={values.extraPayment === "true"}
                              onChange={() => setFieldValue("extraPayment", "true")}
                              style={{width: 'auto', marginRight: '3px'}}
                          />
                          Is this an extra payment and your automatic payment on {loan.nextPaymentDate} will still be processed for the Amount of {loan.currentDueBalance}?
                        </label>
                      </div>
                    </ContentBox>
                  </div>
                </div>}
                <hr/>
                {isValid && dirty && (
                  <PaymentInfoBox
                    className="w-75"
                    paymentInfoValues={{
                      date: values.date,
                      amount: parseFloat(values.amount.replace(/,/g, '')),
                      ...(cardPromoEnabled && isCreditCardPromoCovered && currentPaymentMethod['transactionType'] === 'creditcard'
                        ? {}
                        : { convenienceFee: `${showCardFee ? creditCardFee : 0}` }
                      )
                    }}
                    cancelNextScheduledPayment={cancelNextScheduledPaymentStatus}
                  />
                )}
                <div className={'d-flex mt-4 justify-content-sm-center justify-content-md-start'}>
                  <Button type="reset" onClick={() => resetFormState()}
                          className={'btn-custom-primary btn-custom-sm m-2 text-white'} title={'Cancel'}/>
                  <Button type="submit" disabled={!isValid || !dirty} className={'alert-button m-2 text-white'}
                          title={'Submit'}/>
                </div>
              </Form>
            }}
          </Formik>}
        </div>}
  </div>
}

export default MakePayment;
